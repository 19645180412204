/* Did Boostrap 3 change the name of a class and you
 * don't want to go back and change all the instances
 * where it is used? Add the class here and try to mimic
 * the old behavior. 
 */
.img-responsive {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: $spacer;
}