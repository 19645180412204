.secondary-message-area {
  .info-bar {
    //display: block;
    display: flex;
    flex-flow: column;
    background-color: inherit;
    color: $white;
    max-height: 100%;
    min-height: 450px;
    // Default caption
    .caption {
      padding: 2em;
      order: 3;
      flex: 1;
      flex-basis: 50%; 
      display: flex;
      flex-flow: column;
      justify-content: center; 
    }
    // Left, right, and centered caption
    &.info-bar-left,
    &.info-bar-center,
    &.info-bar-right {
      .caption {
        text-align: center;
      }
    }
    .img-responsive {
      width: 100%; // Don't change this.
      margin-bottom: 0;
    }
    .image {
      order: 1;
      flex: 1;
      flex-basis: 50%;
      background-size: cover;
      background-position: center;
      margin-top: -0.1em;
      max-height: 400px;
      &:before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;
      }
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .secondary-message-area {
    .info-bar {
      display: flex;
      flex-flow: row nowrap;
      //max-height: 900px;
      .image {
        margin: 0;
        min-width: 40%;
        max-height: 100%;
        //flex-basis: 50%;
      }
      .caption {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        //flex-basis: 50%;
      }
      &.info-bar-left {
        .image {
          order: 1;
        }
        .caption {
          order: 2;
          text-align: left;
        }
      }
      &.info-bar-right {
        justify-content: flex-end;
        .image {
          order: 2;
        }
        .caption {
          order: 1;
          text-align: right;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .secondary-message-area {
    .info-bar {
      //max-height: 750px;
      .image {
        min-width: 0%;
        //min-width: 50%;
        //flex-basis: 50%;
      }
    }
  }
}
