// Flex and position related styles for all devices.
.events {
  background-color: $brand-color-4;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: $sma-border-width solid $sma-border-color;
  .event-container {
    display: flex;
    flex-flow: column nowrap; // xs, sm
    max-width: $container-max-width;
    .event-item,
    a {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-end;
      color: $white;
      text-align: left; // xs, sm
    }
    .event-menu {
      flex: 1;
      padding: 10px;
      text-align: center; // xs, sm
      a {
        text-align: inherit;
      }
      .title {
        font-size: 2.45em;
        font-weight: bold;
      }
      .links {
        font-size: 1.3em;
        text-transform: uppercase;
      }
    }
    .event-cards {
      flex: 2;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      padding: 0; // sm, xs
      .event-card {
        background-color: $event-card-bg-color;
        flex: 1 100%; // xs, sm
        margin: 0 0 15px 0; // xs, sm
        min-height: 125px;
        padding: 15px 15px 0 15px; // xs, sm
        .date {
          margin-bottom: 0;
          font-size: 1.3em;
        }
        .title {
          font-size: 1.5em;
          font-weight: bold;
          margin-bottom: 15px; // xs, sm
        }
      }
      a.event-card:hover {
        cursor: pointer;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .events {
    .event-container {
      flex-flow: row wrap; // md
      .event-item,
      a {
        text-align: right; // md
      }
      .event-menu {
        text-align: right;
        flex: 1;
      }
      .event-cards {
        flex: 2;
        padding: 10px 0 0 10px; // md
        .event-card {
          flex: 1 100%; // md
          margin: 0 10px 10px 0; // md
          padding: 30px 10px 0 30px; // md
          .title {
            margin-bottom: 10px; // md
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .events {
    .event-container {
      .event-cards {
        .event-card {
          flex: 0 48%;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .events {
    .event-container {
      .event-item,
      .event-menu {
        flex: 1;
      }
      .event-cards {
        flex: 2;
      }
    }
  }
}
