// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Colors system (color description derived from https://www.colorhexa.com/)
$light-grayish-blue:      #ececed; // light grayish blue
$pure-yellow:             #fecb00; // pure (or mostly pure) yellow
$strong-yellowish-green:  #bed600; // strong yellowish green
$strong-cyan-100:         #00a4c7; // strong cyan
$strong-cyan-200:         #0094b3; // strong cyan
$dark-cyan-050:           #00849f; // dark cyan
$dark-cyan-100:           #00829b; // dark cyan
$dark-cyan-150:           #007287; // dark cyan
$dark-cyan-200:           #006778; // dark cyan
$very-dark-cyan:          #003d4c; // very dark cyan
$very-dark-grayish-yellow:#55554d; // very dark grayish yellow
$very-dark-grayish-orange:#363534; // very dark grayish orange
$very-dark-gray:          #1e1e1e; // very dark gray (mostly black)

$translucent-black-90: rgba(0, 0, 0, 0.9);
$translucent-black-80: rgba(0, 0, 0, 0.8);
$translucent-black-75: rgba(0, 0, 0, 0.75);
$translucent-black-70: rgba(0, 0, 0, 0.7);
$translucent-black-60: rgba(0, 0, 0, 0.6);
$translucent-black-50: rgba(0, 0, 0, 0.5);
$translucent-black-40: rgba(0, 0, 0, 0.4);
$translucent-black-30: rgba(0, 0, 0, 0.3);
$translucent-black-25: rgba(0, 0, 0, 0.25);
$translucent-black-20: rgba(0, 0, 0, 0.2);
$translucent-black-15: rgba(0, 0, 0, 0.15);
$translucent-black-10: rgba(0, 0, 0, 0.1);
$translucent-black-0:  rgba(0, 0, 0, 0.0);

$translucent-white-90: rgba(255, 255, 255, 0.9);
$translucent-white-80: rgba(255, 255, 255, 0.8);
$translucent-white-75: rgba(255, 255, 255, 0.75);
$translucent-white-70: rgba(255, 255, 255, 0.7);
$translucent-white-60: rgba(255, 255, 255, 0.6);
$translucent-white-50: rgba(255, 255, 255, 0.5);
$translucent-white-40: rgba(255, 255, 255, 0.4);
$translucent-white-30: rgba(255, 255, 255, 0.3);
$translucent-white-25: rgba(255, 255, 255, 0.25);
$translucent-white-20: rgba(255, 255, 255, 0.2);
$translucent-white-15: rgba(255, 255, 255, 0.15);
$translucent-white-10: rgba(255, 255, 255, 0.1);
$translucent-white-0:  rgba(255, 255, 255, 0.0);

// Color system (overrides default Bootstrap colors)
$white:    #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #636b72;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #1a1a1a;
$red:      #dc3545;

$primary:     $dark-cyan-100;
$secondary:   $gray-600;
$success:     $strong-yellowish-green;
$info:        $strong-cyan-200;
$warning:     $pure-yellow;
$danger:      $red;
$light:       $light-grayish-blue;
$dark:        $gray-800;

/* JCCC brand colors
 * These are the actual brand colors. Some variation is required to meet
 * accessibility standards. For example, a button may require a darker version
 * of a specific brand color to provide sufficient contrast according to WCAG
 * guidelines.
 */
$brand-color-0: $very-dark-gray;
$brand-color-1: $very-dark-grayish-orange;
$brand-color-2: $very-dark-cyan;
$brand-color-3: $dark-cyan-200;
$brand-color-4: $strong-cyan-200;
$brand-color-5: $strong-yellowish-green;
$brand-color-6: $pure-yellow;

// Options
$enable-prefers-reduced-motion-media-query: true;

// Containers
// Main content max width
$container-max-width: 1920px;

// Typography
$font-family-sans-serif: "AvenirNextLTW01-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-demi: "Avenir Next LT W01 Demi", $font-family-sans-serif;
$headings-font-family: "Montserrat", $font-family-sans-serif;

$font-size-base:   1rem; // Assumes the browser default, typically `16px`
$font-size-lg:     $font-size-base * 1.25;
$font-size-sm:     $font-size-base * .87;

$lead-font-size:   $font-size-base * 1.8;
$lead-font-weight: 300;

// Spacers
$spacer: 1rem;

// Links
$link-decoration: none;

// Borders (general)
$border-width: 1px; // Default border size

$hr-border-color: rgba($black, .25);
$hr-border-width: $border-width;
$hr-margin-y:     $spacer;

// Blockquotes
$blockquote-font-size: $font-size-base * 1.10;

// Buttons
// Allows for customizing button radius independently from global border radius
$btn-border-radius:    0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-transition:       color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;

// Buttons (custom)
$btn-primary-bg-color: $dark-cyan-150;
$btn-primary-bg-hover-color: darken($btn-primary-bg-color, 7.5%);
$btn-ghost-bg-color:        $white;
$btn-ghost-dark-bg-color:   $dark;
$btn-default-bg-color:      $light;

// Alerts
//
// Define alert colors, border radius, and padding.
/*
$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            $font-weight-bold !default;
$alert-border-width:                $border-width !default;
*/
$alert-bg-level:                    -10;
$alert-border-level:                -9;
$alert-color-level:                 10;

// Offcanvas Menu
// Using this font family was a mistake, but since it has been in production for
// some time, best not to change it lest it be preceived as an error.
$offcanvas-font-family:                       "Helvetica Neue", Arial, sans-serif;
$offcanvas-btn-color:                         $white;
$offcanvas-btn-bg-color:                      $brand-color-2;
$offcanvas-btn-bg-color-hover:                $brand-color-1;
$offcanvas-btn-bg-color-hover-translucent:    $brand-color-1;
$offcanvas-btn-color-ql:                      $white;
$offcanvas-btn-bg-color-ql:                   $btn-primary-bg-color;
$offcanvas-btn-bg-color-ql-hover:             $btn-primary-bg-hover-color;
$offcanvas-btn-width:                         200px;
$offcanvas-nav-width:                         380px;
$offcanvas-nav-transition:                    0.5s;
$offcanvas-utility-link-hover-transition:     0.3s;
$offcanvas-utility-link-color:                $gray-400;
$offcanvas-noindex-color:                     $gray-500;
$offcanvas-sitetree-toggle-size:              32px; // In pixels
$offcanvas-sitetree-border-color:             $translucent-white-20;

// Navbars
$navbar-primary-brand-color:          $brand-color-1;
$navbar-primary-brand-hover-color:    $brand-color-1;
$navbar-primary-color:                $primary;
$navbar-primary-hover-color:          $primary;
$navbar-primary-active-color:         $brand-color-1;
$navbar-primary-active-weight:        bold;
$navbar-primary-disabled-color:       $secondary;
$navbar-primary-border-color:         $brand-color-1;
$navbar-primary-border-width:         2px;
$navbar-primary-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-primary-brand-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-primary-toggler-border-color: transparent;

// Page Navigation Navbar
$page-navigation-navbar-border-color: $brand-color-1;

// Page Navigation
$navigation-color: $gray-400;
$navigation-link-color: $white;
$navigation-active-link-color: $btn-primary-bg-color;
$navigation-hover-link-color: lighten($brand-color-2, 7.5%); 
$navigation-bg-color: $brand-color-2;
$navigation-border-color: $translucent-white-50;

// Footer
$footer-color:      $white;
$footer-bg-color:   $brand-color-1;
$footer-rule-color: $translucent-white-50;

// Notifications
$notification-padding: 40px; // To account for offcanvas buttons
$notification-general-bg-color: $primary;
$notification-weather-bg-color: $pure-yellow;
$notification-warning-bg-color: $pure-yellow;
$notification-emergency-bg-color: $red;
$cookie-notification-color:    $white;
$cookie-notification-bg-color: $brand-color-1;
$cookie-notification-space:    40px;

// Cards
$card-border-width: $border-width;

// Accordion cards
$accordion-card-border-width: $card-border-width;
$accordion-card-border-color: $white;
$accordion-card-border-radius: 0;
$accordion-card-header-bg: linear-gradient(45deg, $very-dark-cyan 0%, $dark-cyan-200 45%, $dark-cyan-200 100%);
$accordion-card-header-color: $white;
$accordion-card-spacer-y:  0rem;
$accordion-card-spacer-x:  0rem;

// Affiliates
$affiliates-color: $white;
$affiliates-bg-color: $brand-color-1;
$affiliates-border-color: $brand-color-5;

// Testimonials
$testimonial-bg-color: $light;
$testimonial-min-width-desktop: 0;
$testimonial-font-size: 1em;
$testimonial-font-size-attribution: 1.6em;
$testimonial-border-arrow-size: 1.6em;
$testimonial-border-arrow-color: $white;
$testimonial-caption-padding: 0.8em; // Applies to the caption itself.
$testimonial-caption-margin: 0.4em; // Applies to elements inside the caption.

// Gallery
$gallery-modal-bg-color: $very-dark-grayish-orange;
$gallery-modal-border-color: $gray-700;
$gallery-modal-color: $light;
$gallery-play-button-color: $translucent-white-90;
$gallery-play-button-hover-color: $white;

// Secondary Message Areas
$sma-border-width: 4px;
$sma-border-color: $white;

// Combo Tiles
$combo-tile-gradient: rgba(0, 25, 35, 0.8);

// Archive list (events, newsroom, experiences)
$archive-list-rule-color: $hr-border-color;
$experiences-border-color: $light-grayish-blue;
$experiences-accent-color: $strong-yellowish-green;

// Events
$event-card-bg-color: $primary;

// Thumbnail carousel
$thumbnail-carousel-title-container-offset: 12px;
$thumbnail-carousel-border-color: $gray-600;

// Sidebar widgets
$sidebar-widget-title-bg-color: rgba(0, 61, 76, 0.8); // For callouts with an image

//
// Migrated from Bootstrap 3
// Try not to use these unless necessary.
//

// Font sizes
$fontSize8XL: 6em;
$fontSize7XL: 5em;
$fontSize6XL: 4em;
$fontSize5XL: 3em;
$fontSize4XL: 2.75em;
$fontSize3XL: 2.5em;
$fontSize2XL: 2.25em;
$fontSizeXL:  2em;
$fontSizeL:   1.75em;
$fontSizeM:   1.5em;
$fontSizeS:   1.25em;
$fontSizeXS:  1em;
$fontSize2XS: 0.9em;
$fontSize3XS: 0.8125em;
