/** item-size calculates the width of flexbox items 
  * for various column numbers.
  */
@mixin item-size($containerWidth, $padding) {
  //$padding: $padding/2;
  //$containerWidth: $containerWidth - $padding - 1;

  // Handles columns.
  &.item-container-col-2 .item {
    flex-basis: #{($containerWidth / 2)-$padding}px;
  }
  &.item-container-col-3 .item {
    flex-basis: #{($containerWidth / 3)-$padding}px;
  }
  &.item-container-col-4 .item {
    flex-basis: #{($containerWidth / 4)-$padding}px;
  }
  &.item-container-col-5 .item {
    flex-basis: #{($containerWidth / 5)-$padding}px;
  }
  &.item-container-col-default .item {
    flex-basis: #{($containerWidth / 3)-$padding}px;
  }
}

/* Flexbox styles */
.experiences.item-container {
  display: flex;
  flex-flow: column nowrap;
  .item {
    display: flex;
    flex-flow: column nowrap;
    .details {
      flex: 1;
    }
  }
  &.item-container-col-2 .item,
  &.item-container-col-3 .item,
  &.item-container-col-4 .item,
  &.item-container-col-5 .item,
  &.item-container-col-default .item {
    flex-basis: auto;
  }
}

@include media-breakpoint-up(md) {
  .experiences.item-container {
    flex-flow: row wrap;
    // justify-content: space-between;
    justify-content: flex-start;
    @include item-size(690, 15);
  }
}
@include media-breakpoint-up(lg) {
  .experiences.item-container {
    @include item-size(930, 15);
  }
}
@include media-breakpoint-up(xl) {
  .experiences.item-container {
    @include item-size(1110, 15);
  }
}

/* Non-flexbox styles */
.experiences.item-container {
  padding: 0;
  margin-bottom: $spacer * 2;
  .item {
    margin: 0 0 15px 0;
    border: 2px solid $experiences-border-color;
    .thumb {
      img {
        width: 100%;
        margin-bottom: 0;
      }
    }
    .details {
      border-top: 6px solid $experiences-accent-color;
      padding: 0 10px;
      h3 {
        font-size: 120%;
        margin-top: 10px;
        font-weight: bold;
      }
      background-color: $white;
    }
    .links {
      a {
        text-decoration: none;
      }
      text-transform: uppercase;
      background-color: $white;
      padding: 10px;
    }
  }
}

@include media-breakpoint-up(md) {
  .experiences.item-container {
    .item {
      margin: 0 15px 15px 0;
      .thumb {
        img {
          width: auto;
        }
      }
    }
  }
}
