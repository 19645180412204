// Migrated from Bootstrap 3, refactor as neccessary

// Page Header
.page-header {
  align-items: center;
  background-color: $brand-color-1;
  background-size: cover;
  background-position: center;
  border: 0;
  border-bottom: $sma-border-width solid $sma-border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  min-height: 120px; // Height of logo plus it's margin and padding.
  padding: 0;
  position: relative; // Allows for absolute branding positioning.
  filter: contrast(1.2);

  .page-title-container {
    font-size: 0.6em;
    padding: 10em 0 5em 0;
    margin: 0 1.2em;

    .page-title {
      width: 100%;

      h1 {
        font-size: 4em;
        color: $white;
        text-align: center;
        width: 100%;
        max-width: 1140px; // Grow no wider than a fixed page column.
        margin: 0;
        padding: 0;
      }
    }
  }

  // Small header, not small screen size
  &.page-header-small {
    .branding {
      background: none;
    }
  }
}

@include media-breakpoint-up(sm) {
  .page-header {
    .page-title-container {
      font-size: 1em;
      padding: 10em 0;
      margin: 0 10em;
    }
  }
}

@include media-breakpoint-up(md) {
  .page-header {
    .page-title-container {
      .page-title {
        h1 {
          font-size: 4em;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-header {
    &.page-header-small {
      .page-title-container {
        padding: 1em;
        margin: 0 22.5em;

        .page-title {
          h1 {
            font-size: $fontSizeM;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .page-header {
    .page-title-container {
      font-size: 1.3em;
    }
    &.page-header-small {
      .page-title-container {
        padding: 1em;
        margin: 0 17.5em;

        .page-title {
          h1 {
            font-size: $fontSizeM;
          }
        }
      }
    }
  }
}

// Branding (migrated)
.branding {
  position: absolute; // homepage
  // position: relative; // other pages
  overflow: hidden;
  // height: 322px; // homepage
  // min-height: $headerMinHeight; // other pages
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, $translucent-black-25 0%, $translucent-black-0 100%);
  z-index: 690;

  .branding-logo {
    position: relative;
    display: block;
    text-decoration: none;
    z-index: 700;
    margin: 8px;
    width: auto;

    img {
      width: 220px;
    }
  }
}

// Force page title to be higher than the gradient.
.page-header {
  .page-title-container {
    z-index: 685;
  }
}

@include media-breakpoint-up(sm) {
  .branding {
    text-align: left;
    .branding-logo {
      margin: 20px 40px;

      img {
        width: 322px;
      }
    }
  }
}
