#sidebar .widget {
  margin-bottom: 22px;
}

#sidebar .widget {
  padding: 20px 20px 10px 20px;
  background-color: $light;
  h4 {
    text-transform: capitalize;
  }
  p {
    a:not(.btn) {
      font-weight: normal;
      text-decoration: underline;
      color: darken($primary, 10%);
    }
  }
  ul {
    list-style: none;
    list-style-position: outside;
    padding: 0;
    margin: 10px 0;
  }
  li {
    margin: 10px 0;
    > a {
      padding: 6px 6px 6px 0;
      color: inherit;
      &:hover {
        text-decoration: none;
        background-color: $btn-primary-bg-color;
        color: $white;
        .fa-chevron-right,
        .fa-folder {
          color: $white;
        }
      }
      .fa-chevron-right,
      .fa-folder {
        color: $btn-primary-bg-color;
      }
      &.active {
        text-decoration: none;
        background-color: $btn-primary-bg-color;
        color: $white;
        .fa-chevron-right {
          color: $white;
        }
      }
    }
  }
  .color-1 {
    border-color: $brand-color-1 !important;
  }
  .color-2 {
    border-color: $brand-color-2 !important;
  }
  .color-3 {
    border-color: $brand-color-3 !important;
  }
  .color-4 {
    border-color: $brand-color-4 !important;
  }
  .color-5 {
  }
  .color-6 {
    border-color: $brand-color-6 !important;
  }
  .column.column-image {
    background-color: $brand-color-2;
    color: $white;
    position: relative;
    border: 0;
    border-radius: 0;
    padding: 0;
    .column-inner {
      position: relative;
      width: 100%;
      overflow: hidden;
      border: 0;
      > {
        img,
        a > img {
          display: block;
          height: auto;
          max-width: 100%;
          line-height: 1;
          border: 0;
        }
      }
    }
    .column-title {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      z-index: 10;
      padding: 10px;
      color: $white;
      background-color: $sidebar-widget-title-bg-color;
      border: 0;
      border-left: 20px solid $brand-color-3;
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      a {
        color: $white !important;
      }
    }
    .column-body {
      padding: 12px 16px;
      a {
        color: $white !important;
      }
    }
    &.media {
      margin-top: 0 !important;
      .column-inner .column-title {
        .title {
          color: $brand-color-5;
          font-size: 240%;
        }
        .caption {
          text-transform: none;
          font-size: 160%;
        }
      }
    }
  }
  &.callout {
    padding: 0;
  }
  .panel,
  .panel-heading {
    color: $white;
    background-color: transparent;
    padding-left: 0;
    margin: 0;
    border: 0;
  }
  .panel-body {
    padding: 0;
    margin: 0;
  }
  .panel-group .panel-heading + .panel-collapse .panel-body {
    border-top: 0px;
  }
  .panel-title i.fa-chevron-down {
    color: $brand-color-5 !important;
    float: right;
  }
  ul li {
    &:first-child {
      margin-top: 4px !important;
    }
    &:last-child {
      margin-bottom: 4px !important;
    }
  }
  .newsroom {
    margin-top: 14px;
    .media {
      border-bottom: 0px solid $translucent-black-10;
      padding-bottom: 8px;
      &:last-child {
        border-bottom: 0px;
        padding-bottom: 2px;
      }
    }
    a {
      padding: 0 !important;
    }
    .media-heading {
      a {
        color: inherit;
        font-weight: normal;
        padding: 0 !important;
        font-size: 86%;
      }
      small {
        font-size: 70%;
      }
      .glyphicon {
        display: none;
      }
    }
    .media-body {
      margin: 0;
      padding: 0;
      .media-content {
        display: none;
      }
    }
  }
  &.releases .newsroom .media {
    border-bottom: 0px solid $translucent-black-10;
    padding-bottom: 0px;
  }
}

#sidebar {
  .widget {
    &.widget-full {
      padding: 0 !important;
      background-color: transparent !important;
      h4 {
        display: none;
      }
    }
    .body {
      a:not(.btn) {
        /* Underline links in user generated paragraphs that
         * appear in the sidebar. */
        font-weight: normal;
        text-decoration: underline;
        color: darken($primary, 10%);
      }
      .btn {
        // Don't underline buttons in the sidebar.
        text-decoration: none;
      }
    }
    .newsroom {
      .media {
        > a {
          margin-right: 12px;
        }
        .media-object {
          width: 80px;
          padding: 0;
          margin: 0;
          border: 0;
          &.empty {
            width: 92px;
            background-color: inherit;
            color: $light;
          }
          &.release {
            display: none;
          }
        } // .media-object
      } // .media
    } // .newsroom

    //map
    &.map-gallery {
      .map-link {
        position: relative;
        display: block;
        margin-top: 15px;
        .view-map {
          position: absolute;          
          bottom: 0;
          right: 0;
          background-color: #ffc423cc;
          color: $black;
          padding: 6px;
          font-weight: bold;
        }
      }      
    }
    // .map
  } // .widget
} // #sidebar
