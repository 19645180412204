/* Styles for print media */
@media print {
  .skipnav,
  .cookie-notification {
    display: none !important;
  }

  #page-content {
    .page-header,
    #post-navigation-target,
    #sidebar,
    footer {
      display: none;
    }
  }

  .offcanvas-overlay, .offcanvas {   
    display: none;  
  }

  a[href]:after {
    content: "";
  }

  .videopane {
    #myVideo, .video-button {
      display: none;
    }
  }

  .back-to-top {
    display: none;
  }  
}
