// Overrides for specific classes on the homepage.
#homepage {
  .page-header {
    min-height: 0px;
    max-height: 0px;
    height: auto;
    border: 0;
    filter: none;
    .branding {
      min-height: 120px; // xs and sm
      @include media-breakpoint-up(md) { min-height: 320px; }
      background: linear-gradient(to bottom, $translucent-black-60 0%, $translucent-black-0 100%);
      @include media-breakpoint-up(md) {
        &.branding-embiggen {
          text-align: center;
          height: 100vh;
          max-height: 1084px; // This shouldn't be hard-coded.
          background: linear-gradient(to bottom, $translucent-black-15 0%, $translucent-black-0 100%);
          .branding-logo {
            width: auto;
            margin: 0;
            img {
              width: 60%;
              height: 90vh;
              max-height: 1084px; // This shouldn't be hard-coded.
            }
          }
        }
      }
    }
    .page-title-container {
      // display: none;
      // For accessibility. Can't be hidden so move it off the page.
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
  .videopane .videopane-content {
    z-index: 695;
    width: 100%;
  }
  #breadcrumbs {
    display: none;
  }
  #content {
    margin-top: 0;
    margin-bottom: 0;
    .wysiwyg-content {
      height: 0;
      display: inline;
    }
  }
  footer {
    //border-top: 0;
  }
}
