// Default devices including extra small

// Flex and position related styles for all devices.
.secondary-message-area {
  .combo-tiles {
    display: flex;
    flex-flow: column nowrap;
    .combo-tile {
      display: flex;
      flex-flow: column nowrap;
      flex-basis: 33.4%;
      flex-grow: 1;
      flex: 1;
      -webkit-flex: 1; // For Chrome and Safari on iOS
      position: relative;
      h2 {
        font-size: 1.5em;
      }
      .caption {
        position: relative; // sm
        bottom: 0; // Removed for flex placement.
        width: 100%;
        height: 100%; // Added for flex placement.
        // Flex placement
        display: flex;
        flex-flow: column nowrap;

        font-size: 1em;
        padding: .5em 1em 0em 1em;

        &.vertical-align-top,
        &.vertical-align-bottom,
        &.vertical-align-center {
          justify-content: center;
        }
      }
      .img-responsive {
        margin-bottom: 0;
      }
    }
  }
}

// Design related styles for all devices and all containers
.secondary-message-area {
  .combo-tiles {
    color: $white;
    .combo-tile {
      // sm
      background-color: $brand-color-2;
      border-left: 0;
      border-bottom: $sma-border-width solid $sma-border-color;
      // /sm
      overflow: hidden;
      // sm
      &:last-child {
        border-bottom: 0;
      }
      // /sm
      .caption {
        text-align: center;
        border: 1px solid $combo-tile-gradient;
        // sm
        padding: 0.5em 1em 1em 1em;
        // /sm
      }
    }
    a.combo-tile {
      color: inherit;
      .caption {
        transition-duration: 0.2s;
        transition-property: background;
        &:hover {
          background: rgba(155, 0, 0, 0) !important;
          text-shadow: 0 1px 6px $translucent-black-90;
        }
      }
    }
  }
}

// Medium devices
@include media-breakpoint-up(md) {
  .secondary-message-area {
    .combo-tiles {
      flex-flow: row nowrap; // md
      .combo-tile {
        border-bottom: 0;
        border-left: $sma-border-width solid $sma-border-color; // md
        &:first-child {
          border-left: 0;
        }
        h2 {
          font-size: 1.35em;
        }
        .caption {
          position: absolute; // md
          padding: 1em;
          font-size: 0.8em;
          padding: .5em .5em 0em .5em;
          justify-content: space-around;
        }
        .btn {
          font-size: 1em;
        }
      }
    }
  }
}

// Large devices
@include media-breakpoint-up(lg) {
  .secondary-message-area {
    .combo-tiles {
      .combo-tile {
        h2 {
          font-size: 1.6em;
        }
        .caption {
          //padding: 2em; // md
          font-size: 1em;
          padding: .5em .5em 0em .5em;
          &.vertical-align-top {
            justify-content: flex-start;
          }
          &.vertical-align-bottom {
            justify-content: flex-end;
          }
          &.vertical-align-center {
            justify-content: center;
          }
        }
        .btn {
          font-size: 0.8em;
        }
      }
    }
  }
}