// Event archive list pages
.archive-list {
  &.item-container {
    display: block;
    padding: 0 0 15px 0;
    width: auto;
    .item {
      border-bottom: 1px solid $archive-list-rule-color;
      display: flex;
      flex-flow: column nowrap;
      margin: 15px 0 0 0;
      padding-bottom: 15px;
      .thumb {
        flex: 1;
        margin-right: 0;
        img {
          width: 100%;
        }
      }
      .location {
        font-weight: bold;
      }
      .details {
        background-color: transparent;
        flex: 3;
        padding: 0 2px;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        h3 {
          margin: 0 0 5px 0;
        }
        p {
          margin: 0 0 5px 0;
        }
      }
      .links {
        color: $primary;
        padding: 0;
        background-color: transparent;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .archive-list {
    &.item-container {
      padding-left: 0;
      padding-right: 0;
      .item {
        flex-flow: row nowrap;
        .thumb {
          margin-right: 15px;
        }
        .details {
          padding: 0 10px 0 0;
        }
      }
    }
  }
}

// Individual events
.event-object {
  background-color: $light;
  display: flex;
  margin: 0 0 $spacer * 1.5 0;
  min-height: 120px;
  @include media-breakpoint-up(lg) {
    min-height: 180px;
  }
  .tear-off {
    background-color: $primary;
    color: $white;
    display: flex;
    flex-flow: column;
    flex: 1;
    font-size: 120%;
    @include media-breakpoint-up(md) {
      font-size: 130%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 140%;
    }
    @include media-breakpoint-up(xl) {
      font-size: 150%;
    }
    font-weight: bold;
    justify-content: center;
    text-align: center;
  }
  .event-details {
    display: flex;
    flex-flow: column;
    flex: 3;
    font-size: 80%;
    justify-content: center;
    padding-left: $spacer/2;
    @include media-breakpoint-up(md) {
      font-size: 86%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 90%;
    }
    @include media-breakpoint-up(xl) {
      font-size: 96%;
    }
    .dl-horizontal {
      margin-bottom: 0;
      dt {
        min-width: 80px;
      }
    }
  }
}
