/** item-size calculates the width of flexbox items 
  * for various column numbers.
  */
@mixin item-size($containerWidth, $padding) {
    //$padding: $padding/2;
    //$containerWidth: $containerWidth - $padding - 1;

    // Handles columns.
    &.item-container-col-2 .item {
        flex-basis: #{($containerWidth / 2)-$padding}px;
    }
    &.item-container-col-3 .item {
        flex-basis: #{($containerWidth / 3)-$padding}px;
    }
    &.item-container-col-4 .item {
        flex-basis: #{($containerWidth / 4)-$padding}px;
    }
    &.item-container-col-5 .item {
        flex-basis: #{($containerWidth / 5)-$padding}px;
    }
    &.item-container-col-default .item {
        flex-basis: #{($containerWidth / 3)-$padding}px;
    }
}

/* Video Gallery Flexbox styles */
.video-gallery.item-container {
    padding: 0;
    margin-bottom: 2rem;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    .item {
        margin: 0 0 15px 0;
        display: flex;
        flex-flow: column nowrap;
        a {
            position: relative;
        }
        .img-responsive {
            margin-bottom: 0;
        }
        .thumb img {
            width: 100%;
            margin-bottom: 0;
        }
        button.btn-play {
            top: 30%;
            bottom: 50%;
        }
        .details {        
            padding: 0 10px;
            flex: 1;
            background-color: $very-dark-gray;
            h3 {
                font-size: 80%;
                margin-top: 10px;
                font-weight: normal;
                line-height: 1.4;
            }
            a {
                color: $light-grayish-blue;
            }
        }
    }
    &.item-container-col-2 .item,
    &.item-container-col-3 .item,
    &.item-container-col-4 .item,
    &.item-container-col-5 .item,
    &.item-container-col-default .item {
      flex-basis: auto;
    }
}
@include media-breakpoint-up(sm) {
    .video-gallery.item-container {
        margin-right: -30px;
        flex-flow: row wrap;
        justify-content: flex-start;
        .item {
            margin: 0 15px 15px 0;            
        }
        @include item-size(525, 15);
        &.item-container-col-4 .item, &.item-container-col-5 .item  {
            flex-basis: 100%;
            button.btn-play .fa {
                font-size: 88px;
            }
        }        
    }
}
@include media-breakpoint-up(md) {
    .video-gallery.item-container {
        @include item-size(465, 15);
        &.item-container-col-4 .item, &.item-container-col-5 .item {
            flex-basis: 217px;
            button.btn-play .fa {
                font-size: 33px;
            }
        }        
    }
}
@include media-breakpoint-up(lg) {
    .video-gallery.item-container {
      @include item-size(624, 15);    
    }
    
}
@include media-breakpoint-up(xl) {
    .video-gallery.item-container {
      @include item-size(744, 15);
    }
}