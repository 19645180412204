@mixin gutter($loc, $breakpoint, $values...) {
  &--gutter {
    &--#{$loc} {
      @include media-breakpoint-up($breakpoint) {
        &--xsmall {
          padding-#{$loc}: nth($values, 1);
        }
        &--small {
          padding-#{$loc}: nth($values, 2);
        }
        &--medium {
          padding-#{$loc}: nth($values, 3);
        }
        &--large {
          padding-#{$loc}: nth($values, 4);
        }
        &--xlarge {
          padding-#{$loc}: nth($values, 5);
        }
      }
    }
  }
}

.videopane {
  position: relative;
  border-bottom: $sma-border-width solid $sma-border-color;
  min-height: auto;
  .videopane-background {
    position: relative;
    background-color: $black;
    overflow: hidden;
    .videopane-video {
      display: none;
    }
    .videopane-fallback {
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //height: auto;
      //min-height: 100%;
      //min-width: 100%;
      //width: auto;
      //transform: translate(-50%, -50%);
      img {
        width: 100%;
      }
    }
  }
  .video-button {
    position: absolute;
    padding-bottom: 5%;
    right: 0;
    bottom: 0;
    //display: block;
    display:none;
    z-index: 696;
    #playPause {
      color: $white;
      font-size: 26px;
      margin: 0.5em;
      font-weight: normal;
      vertical-align: text-top;
    }

    #videoButton {
      background-color: $dark-cyan-150;
      border: none;
      &:hover {
        background-color: $very-dark-cyan;
        border: none;
      }
    }    
  }

  .videopane-content {
    position: relative;
    //max-width: 100% !important; // Override of inline styles
    //width: 100%; // Need to figure out how to get this to work for Anne.
    z-index: 30;
    &--align {
      &--top {
        top: 0;
      }
      &--right {
        right: 0;
      }
      &--bottom {
        bottom: 0;
      }
      &--left {
        left: 0;
      }
      &--center {
        top: 50%;
        transform: none;
      }
    }

    @include gutter(top, xl, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(right, xl, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(bottom, xl, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(left, xl, 2.5%, 5%, 15%, 25%, 50%);

    @include gutter(top, lg, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(right, lg, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(bottom, lg, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(left, lg, 2.5%, 5%, 15%, 25%, 50%);

    @include gutter(top, md, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(right, md, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(bottom, md, 2.5%, 5%, 15%, 25%, 50%);
    @include gutter(left, md, 2.5%, 5%, 15%, 25%, 50%);

    // Override the inline styles on extra small and small screens
    @include media-breakpoint-down(sm) {
      width: 100% !important; // Override of inline styles
      max-width: initial !important; // Override of inline styles
    }

    .videopane-body {
      // Override the inline styles on extra small and small screens
      @include media-breakpoint-down(sm) {
        background-color: $brand-color-1 !important; // Override of inline styles
      }
      color: $white;
      padding: 1.4em;
      padding-bottom: 0.4em;
      h2 {
        margin-top: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .videopane {
    min-height: 100vh;
    .videopane-background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .videopane-video {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        transform: translate(-50%, -50%);
        z-index: 20;
      }
      .videopane-fallback {
        position: absolute;
        top: 50%;
        left: 50%;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        transform: translate(-50%, -50%);
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          height: auto;
          min-height: 100%;
          min-width: 100%;
          width: auto;
          transform: translate(-50%,-50%);
        }
      }
    }
    .video-button {
      display:block;
    }
    .videopane-content {
      position: absolute;
      &--align {
        &--center {
          transform: translate(0%, -50%);
        }
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  #myVideo,
  #videoButton {
    display: none;
  }
}
