/* Migrated */
.landing {
  &.one-column {
    .share-buttons {
      text-align: center;
      margin-top: 30px;
    }
  }
  .btn {
    display: block;
    width: 100%;
  }
}

.cta {
  background-color: $brand-color-5;
  margin: 0 0 $spacer * 1.5 0;

  padding: 14px 0;
  p {
    margin: 0;
    padding: 0;
    font-size: 140%;
  }
  .kicker {
    margin: 0;
    padding: 5px 0;
    margin: 0 16px 8px 16px;
    padding: 0;
    text-align: center;
  }
  .btn {
    margin: 0;
    padding: 4px;
    font-size: 100%;
  }
}

#landing-media {
  .column.column-image {
    background-color: $brand-color-2;
    color: $white;
    position: relative;
    border: 0;
    border-radius: 0;
    padding: 0;
    .column-inner {
      .img-responsive {
        margin-bottom: 0;
      }
      position: relative;
      width: 100%;
      overflow: hidden;
      border: 0;
      > {
        img,
        a > img {
          display: block;
          height: auto;
          max-width: 100%;
          line-height: 1;
          border: 0;
        }
      }
    }
    .column-title {
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      a {
        color: $white !important;
      }
    }
    .column-body {
      a {
        color: $white !important;
      }
    }
    &.media {
      margin-top: 0 !important;
      .column-inner .column-title {
        padding: 1rem;
        margin-bottom: 0;
        .title {
          color: $brand-color-5;
          margin-bottom: 1rem;
        }
        .caption {
          text-transform: none;
          font-size: 70%;
        }
      }
    }
  }
}

#landing-primary-navigation {
  // Not sure about this. Why doesn't a zero margin work?
  margin-top: -$spacer * 1.35;
  margin-bottom: $spacer * 1.5;
}

#landing-secondary-navigation {
  .widgets {
    .btn {
      display: block;
      width: 100%;
    }
  }
  .col-md-12 {
    .row:not(.cta) {
      background-color: $light;
      margin: 0 0 $spacer * 1.5 0;
      padding: 16px 0 0 16px;
    }
  }
  .col-md-4 {
    margin: 0 0 16px 0;
    padding: 0 16px 0 0;
    display: flex;
    .widget {
      flex-grow: 1;
      min-height: 360px;
      background-color: $brand-color-2;
      color: $white;

      > p {
        margin: 0 0 $spacer 0;
        padding: 0;
        .img-responsive {
          margin-bottom: 0;
        }
      }
      h4, .h4 {
        margin-bottom: $spacer;
        padding: 0 $spacer;
      }
      .body {
        margin-bottom: $spacer;
        padding: 0 $spacer;
      }
    }
  }
}

/* Only used for landing pages now. */
#site-search {
  margin: $spacer auto;
  text-align: center;
  .landing-logo img {
    margin-bottom: $spacer;
  }
  .search-input {
    height: auto;
    .landing-addl-info {
      text-align: center;
    }
  }
}

@include media-breakpoint-up(md) {
  #site-search {
    margin-bottom: 0;
    text-align: left;
    .search-input {
      height: 78px;
      padding-top: 42px;
      .landing-addl-info {
        text-align: right;
      }
    }
    .logo {
      img {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .landing {
    .btn {
      display: inline-block;
      width: initial;
    }
  }
  .cta {
    padding: 12px 0 12px 12px;
    .kicker {
      margin: 0;
      padding: 5px 0;
      text-align: left;
    }
  }
  #landing-secondary-navigation {
    .widgets {
      .btn {
        display: inline-block;
        width: initial;
      }
    }
  }
}
