.stats-section {
  font-size: 1.2rem;
  background: transparent;
  border-bottom: $sma-border-width solid $sma-border-color;
  .btn-link {
    text-decoration: underline;
    padding-left: 0;
  }
  .container {
    p {
      a {
        &:link {
          text-decoration: underline;
        }
      }
    }
  }
}
.fact-grid-wrap {
  display: flex;
  flex-direction: column;
  height: 86%;
  .fact-grid {
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .fact-block {
      width: 100%;
      padding: $spacer;
      flex-grow: 1;
      display: flex; 
      flex-direction: column; 
      justify-content: center;
      border-width: 0;
      border-bottom-width: 1px;
      &:last-child {
        border-bottom-width: 0;
      }
      // border-color: $translucent-black-30;
      border-style: solid;
    }
    // Fact grids with one fact
    &.size-1 {
      // Small and up
      @include media-breakpoint-up(sm) {
        .fact-block {
          font-size: 140%;
          border-width: 0;
        }
      }
    }
    // Fact grids with two facts
    &.size-2 {
      // Small and up
      @include media-breakpoint-up(sm) {
        flex-wrap: nowrap;
        .fact-block {
          border-width: 0;
          &:nth-child(2) {
            border-left-width: 1px;
          }
        }
      }
    }
    // Fact grids with three facts
    &.size-3 {
        // Small and up
        @include media-breakpoint-up(sm) {
        flex-direction: column;
        .fact-block {
          width: 100%;
          padding: $spacer;
        }
      }
    }
    // Fact grids with four facts
    &.size-4 {
      // Small and up
      @include media-breakpoint-up(sm) {
        .fact-block {
          display: block;
          width: 50%;
          padding: $spacer + 1.2 $spacer;
          border-width: 0;
          // first and second fact-block
          &:nth-child(-n + 2) {
            border-bottom-width: 1px;
          }
          // odd fact blocks
          &:nth-child(odd) {
            border-right-width: 1px;
          }
        }
      }
    }
  }
}