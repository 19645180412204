.notification {
  //display: none;
  &.alert {
    border-radius: 0;
    border: 0;
    margin: 0;
  }
  a {
    color: $white;
    font-weight: bold;
    text-decoration: underline;
  }
  .title {
    font-weight: bold;
    text-transform: uppercase;
  }
  .notification-content {
    font-size: 130%;
    padding-right: $notification-padding;
    a {
      font-size: 80%;
      font-weight: normal;
      .fa {
        font-size: 80%;
        font-weight: normal;
      }
    }
  }
  .addl-info {
    margin-top: 12px;
    padding-right: $notification-padding;
  }
  .close {
    text-shadow: none;
    filter: alpha(opacity=80);
    opacity: 0.8;
    margin-top: 2px;
  }
  .fa {
    margin-top: 5px;
    margin-right: 8px;
  }
  .wi {
    margin-right: 8px;
  }
}

@include media-breakpoint-up(md) {
  .notification {
    .notification-content {
      padding-right: $offcanvas-btn-width - 60; // for md
    }
    .addl-info {
      padding-right: $offcanvas-btn-width - 60; // for md
    }
  }
}

/* Notification Themes */
.notification-general {
  background-color: $notification-general-bg-color;
  color: $white;
}

.notification-weather {
  background-color: $notification-weather-bg-color;
  color: $black;

  a {
    color: $black;
  }
}

.notification-warning {
  background-color: $notification-warning-bg-color;
  color: $black;

  a {
    color: $black;
  }
}

.notification-emergency {
  background-color: $notification-emergency-bg-color;
  color: $white;
}

.notification-noscript {
  background-color: $notification-general-bg-color;
  color: $white;
  display: block;
  padding-right: 80px;
}

@include media-breakpoint-up(md) {
  .notification-noscript {
    padding-right: $offcanvas-btn-width - 60; // md
  }
}
