// Custom navbar themes

// List pages, colored links on light/white background.
.navbar-primary {

  @include media-breakpoint-up(md) {
    // This is a hack to align the right side of this navbar with
    // experiences items. This theme is primarily used in that context.
    margin-right: 15px; 
  }

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  border-style: solid;
  border-color: $navbar-primary-border-color;
  border-width: $navbar-primary-border-width 0;

  .navbar-brand {
    color: $navbar-primary-brand-color;

    @include hover-focus() {
      color: $navbar-primary-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-primary-color;
      text-decoration: none;
      @include hover-focus() {
        color: $navbar-primary-hover-color;
      }
      &:hover {
        text-decoration: underline;
      }
      &.disabled {
        color: $navbar-primary-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-primary-active-color;
    }

    .active > .nav-link {
      font-weight: $navbar-primary-active-weight;
    }
  }

  .navbar-toggler {
    color: $navbar-primary-color;
    border-color: $navbar-primary-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: escape-svg($navbar-primary-toggler-icon-bg);
  }

  .navbar-text {
    color: $navbar-primary-color;
    a {
      color: $navbar-primary-active-color;
      @include hover-focus() {
        color: $navbar-primary-active-color;
      }
    }
  }
}
