// Affiliates
.affiliates {
  border-top: 10px solid $affiliates-border-color;
  margin-top: -4px; 
  .container-fluid,
  .container {
    display: block;
    padding-right: 15px;
    padding-bottom: 0;
    a {
      background-color: $affiliates-bg-color;
      border-left: 15px solid $affiliates-border-color;
      border-right: 15px solid $affiliates-border-color;
      color: $affiliates-color;
      display: block;
      margin-bottom: 15px;
      margin-right: 15px;
      margin-top: 15px;
      padding: 15px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .affiliates {
    .container-fluid,
    .container {
      display: flex;
      flex-flow: row wrap;
      padding: 0 0 15px 15px;
      a {
        border-right: 0;
        flex-basis: 24%;
        flex-grow: 1;
        display: inline-block;
        margin-bottom: 0;
        text-align: left;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .affiliates {
    .container-fluid,
    .container {
      flex-flow: row nowrap;
      a {
        flex-basis: 16.0%;
      }
    }
  }
}
