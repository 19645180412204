
.college-now__bldg-rep {
  border: 1px solid #ddd;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.1);
  background-color: white;
}
.college-now__bldg-rep__name {
  margin-top: 20px;
  margin-bottom: 20px;
}
.college-now__bldg-rep__name .small {
  margin-top: 8px;
}
.college-now__bldg-rep__contact {
  margin-top: 20px;
  margin-bottom: 20px;
}
// TODO: Replace college now glyphicons with font awesome.
.college-now__bldg-rep__contact .fa {
  font-size: 1.6em;
  color: #757575;
  padding-right: 10px;
  vertical-align: bottom;
}
.college-now__alert {
  margin-top: 20px;
  background-color: #0094b3;
  color: #fefefe;
}
.college-now__courses.table {
  margin-top: 20px;
}

.college-now__courses.table tbody:nth-child(even) {
    background-color: #f5f5f5;
}