/* Not sure if I care for having this here, or in the 
more general partials. */

// Navigation
.areas-of-study-navigation {
  .btn {
    border-radius: 0;
    text-align: left;
    font-size: 1.8em;
    &.btn-default {
      background-color: $brand-color-2;
      color: $white;
      &:hover {
        color: $white;
        //background-color: brandColor4Highlight;
      }
    }
  }
}

// Accordion
#area-of-study {
  .card {
    ul {
      list-style-type: none;
      font-size: 1.2em;
      padding-left: 0;
      li {
        a {
          text-decoration: none;
        }
      }
    }
  }
}