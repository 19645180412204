.secondary-message-area,
#featured-messaging-v2 {
  padding: 0;
  border-bottom: $sma-border-width solid $sma-border-color;
  .img-responsive {
    width: 100%;
  }
  a:hover {
    text-decoration: none;
  }
  .dropshadow {
    text-shadow: rgba(22, 22, 22, 0.6) 0px 1px 2px;
  }
}

// Extra small
.secondary-message-area,
.videopane,
.tiles {
  h2 {
    font-size: $fontSizeXL;
  }
  h3 {
    font-size: $fontSizeL;
  }
  p {
    font-size: $fontSizeS;
    line-height: 1.25;
    margin-bottom: 0.75em;
  }
}

@include media-breakpoint-up(md) {
  .secondary-message-area,
  .videopane,
  .tiles {
    h2 {
      font-size: $fontSizeL;
    }
    h3 {
      font-size: $fontSizeM;
    }
    p {
      font-size: $fontSizeXS;
      line-height: 1.25;
      margin-bottom: 0.75em;
    }
  }
}
@include media-breakpoint-up(lg) {
  .secondary-message-area,
  .videopane,
  .tiles {
    h2 {
      font-size: $fontSizeL;
    }
    h3 {
      font-size: $fontSizeM;
    }
    p {
      font-size: $fontSizeXS;
      line-height: 1.25;
      margin-bottom: 0.75em;
    }
  }
}
@include media-breakpoint-up(xl) {
  .secondary-message-area,
  .videopane,
  .tiles {
    h2 {
      font-size: $fontSize2XL;
    }
    h3 {
      font-size: $fontSizeXL;
    }
    p {
      font-size: $fontSizeS;
      line-height: 1.25;
      margin-bottom: 1em;
    }
  }
}
