// Override WWW 2015 because Carlsen and Nerman still require addition margin.
.facility {
  margin-top: 20px !important;
}

// Fix for ActOn forms that disable the outline on focused checkboxes.
label.ao-combo-label input[type="checkbox"]:focus {
  outline: auto;
}

// Hack to get video to maintain aspect ratio in a secondary message are. See todo.
.secondary-message-area-custom .embed-responsive {
  // TODO: Find out why Bootstrap 3 set height to 0,  whereas Bootstrap 4 does not.
  height: 0; 
}