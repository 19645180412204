.media-object {
  img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 12px;
  }
}

.media-object-list {
  img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 12px;
  }
}

hr.media-object-style {
  border: 0;
  height: 0;
  border-top: 1px solid $light;
  margin-top: 10px;
  margin-bottom: 10px;
}

h4.media-object-style {
  margin-top: 0;
}
