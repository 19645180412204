.erp {
  border: 1px solid $red;
  padding: 26px;
  margin-bottom: $spacer;
  .erp-header {
    width: 100%;
    background: repeating-linear-gradient(-45deg, $red,  $red 10px, $white 10px,  $white 20px);
    //background-size: 30px 80px;
    margin-bottom: 18px;
  }
  .erp-title {
    color: $red;
    font-weight: bold;
    font-size: 120%;
    text-transform: uppercase;
    background-color: $white;
    margin-left: 16%;
    padding: 5px 14px;
  }
  .erp-caption {
    font-style: italic;
    margin-bottom: $spacer;
  }
  .col-md-6 {
    font-size: 90%;
    ul {
      margin-top: 10px;
      list-style-position: outside;
    }
  }
}