.secondary-message-area {
  .image-text-overlay {
    color: $white;
    background-size: cover;
    background-position: center center;
    padding: 0 5%;
    min-height: 0 !important; // is this neccessary?

    // Flex styles
    // Tested
    .container-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // Image focus left, text alignment right.
    // Tested
    &.image-left {
      background-position: left;
    }

    // Image focus right, text alignment left.
    // Tested
    &.image-right {
      background-position: right;
    }

    // Explicit text alignment overrides what's set above.
    // Tested
    &.image-right,
    &.image-left,
    &.image-center {
      .caption {
        &.text-right {
          width: 100%;
          text-align: right;
        }
        &.text-left {
          width: 100%;
          text-align: left;
        }
        &.text-center {
          width: 100%;
          text-align: center;
        }
      }
    }

    // Deafult caption behavior
    // Tested
    .caption {
      text-align: center;
      padding-left: 0; // xs
      padding-right: 0; // xs
      width: 100%; // xs
    }
  }
}

@include media-breakpoint-up(sm) {
  .secondary-message-area {
    .image-text-overlay {
      // min-height: 220px !important; // is this neccessary?

      // Deafult caption behavior
      // Tested
      .caption {
        width: 80%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .secondary-message-area {
    .image-text-overlay {
      // Image focus left, text alignment right.
      // Tested
      &.image-left {
        .container-center {
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center;
          .caption {
            text-align: right; // md
          }
        }
      }

      // Image focus right, text alignment left.
      // Tested
      &.image-right {
        .container-center {
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          .caption {
            text-align: left; // md
          }
        }
      }

      // Explicit text alignment overrides what's set above.
      // Tested
      &.image-right,
      &.image-left,
      &.image-center {
        .caption {
          &.text-right {
            padding-left: 50%;
          }
          &.text-left {
            padding-right: 50%;
          }
          &.text-center {
            padding-left: 25%;
            padding-right: 25%;
          }
        }
      }

      // Deafult caption behavior
      // Tested
      .caption {
        width: 50%; // md
      }
    }
  }
}
