// Flex and position related styles for all devices.
.secondary-message-area {
  .feature-list {
    .container-center {
      max-width: 1440px;
    }
    .features {
      display: flex;
      justify-content: center;
      flex-flow: column nowrap;
      .feature {
        flex-basis: 33%;
        flex: 1;
        -webkit-flex: 1; // For Chrome and Safari on iOS
        img {
          margin: 0 auto;
        }
        .caption {
          text-align: center;
        }
      }
    }
  }
}

// Design related styles for all devices.
.secondary-message-area {
  .feature-list {
    &.feature-list-borders {
      .features {
        padding-top: 20px; // xs, sm
        .feature {
          padding-bottom: 10px; // xs, sm
          img {
            border: 3px solid $brand-color-4;
            padding: 3px;
          }
        }
      }
    }
    text-align: center;
    h2,
    h3 {
      padding: 0 3%;
    }
    .top-caption,
    .bottom-caption {
      padding: 20px 0;
    }
    .lead {
      color: inherit;
      padding: 0 3%;
      opacity: 0.8;
    }
    .half-rule {
      border-color: transparent;
    }
    .features {
      margin: 0 auto;
      .feature {
        padding: 0 2% 0 2%;
        img {
          margin: 0 auto;
        }
        .caption {
          h3 {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .secondary-message-area {
    .feature-list {
      .features {
        flex-flow: row wrap; // md
      }
    }
  }
}

// Specific types of feature lists.

// Experiences
.secondary-message-area {
  &.experiences {
    .feature-list {
      .features {
        .feature {
          .caption {
            h3 {
              margin-top: 15px;
            }
          }
          img {
            border: 3px solid $brand-color-4;
            padding: 3px;
          }
        }
      }
    }
  }
}

// Infographic
@include media-breakpoint-up(xl) {
  #sma-infographic .feature-list {
    padding: 1vw 0;
    .btn {
      font-size: 1.05vw;
    }
    .features .feature .caption h3 {
      font-size: 2.8vw;
    }
  }
}

// Feature list for content that includes a sidebar.
.secondary-message-area {
  &.content-feature-list {
    .feature-list {
      .features {
        .feature {
          padding: 0 2% 0 0;
          &:last-child {
            padding-right: 0;
          }
          .caption {
            h3 {
              margin-top: 15px;
              font-size: $fontSizeS;
            }
            p {
              font-size: $fontSizeXS;
            }
          }
        }
      }
    }
  }
}