:not(.panel-group) > .panel {
  font-size: 1em;
  background-color: transparent;
  border: 4px solid $brand-color-2;
  border-left-width: 18px;
  //margin-top: 20px;
  margin-bottom: $spacer;
  border-radius: 0;
  box-shadow: none;
  padding-top: 1em;
  .panel-heading,
  .panel-title {
    background: none;
    background-color: transparent;
    color: inherit;
    border-bottom: 0;
  }
  .panel-title {
    padding: 10px 15px;
    margin-bottom: 0;
    font-size: 1.4em;
  }
  .panel-body {
    padding: 15px;
  }
}

.list-group-item {
  text-align: center; // xs screens
  background-color: transparent;
  border: 1px solid $gray-400;
  border-width: 1px 0;
  &:first-child {
    border-top: 0;
  }
  &:last-child {
    border-bottom: 0;
  }
  > .btn-link {
    float: none; // xs screens
    display: block; // xs screens
  }
}

@include media-breakpoint-up(md) {
  .list-group-item {
    text-align: left;
    > .btn-link {
      float: right; // medium and up
      padding: 0; // medium and up
      // may need something to set display
    }
  }
}
