/* WYSIWYG Editor in Cascade */
body#tinymce {
    margin: 8px;
}

dl.dl-horizontal {
  display: flex;
  flex-flow: row wrap;
  dt {
    flex-basis: 20%;
    padding: 2px 4px;
    text-align: right;
  }
  dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
  }
}

#content {
  margin-bottom: $spacer;
}

.default-content {
  a:not(.btn) {
    text-decoration: underline;
  }
}

/* Custom Container
 * Constrains a fluid container to the specified max-width.
 * Only applies to certain tags for now.
 */
.container-custom,
.container-center {
  max-width: $container-max-width;
  width: 100%;
  margin: 0 auto;
}

/* Skipnav ensures that the first link on the page will jump the visitor to the
 * main content. Skipnav is hidden until the visitor focuses the link (by 
 * hitting tab). This satisfies WCAG 2.0 G1. 
 */
.skipnav {
  text-align: left;
  a {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
    &:focus,
    &:active {
      position: absolute;
      left: 10px;
      top: 10px;
      width: auto;
      height: auto;
      overflow: visible;
      text-decoration: underline;
      color: $white;
      background-color: $translucent-black-80;
      padding: 10px;
      z-index: 100;
    }
  }
}

.pullquote,
.pullquote-reverse {
  color: $brand-color-3;
  float: left;
  width: 30%;
  font-size: 150%;
  font-weight: bold;
  margin: 0 $spacer*1.5 $spacer*1.5 0;
}

.pullquote-reverse {
  margin: 0 0 $spacer*1.5 $spacer*1.5;
  float: right;
}

/* The following classes try to mimic the behavior of their
 Bootstrap 3 counterparts. This is sloppy. Clean it up. */
.img-responsive {
  @include img-fluid();
}

.img-fluid,
.img-reponsive,
.thumbnail,
figure,
img {
  &.float-left,
  &.pull-left,
  &.float-right,
  &.pull-right {
    max-width: initial;
    width: 100%;
    margin: 0 auto $spacer auto;
    text-align: center;
  }
}

/* Change from media-breakpoint-up(md) at Ian's request. 
They would like floated images to remain that way at all
breakpoints. */
@include media-breakpoint-up(xs) {
  .img-fluid,
  .img-reponsive,
  .thumbnail,
  figure,
  img {
    &.float-left,
    &.pull-left {
      width: auto;
      max-width: 200px;
      margin: 0 $spacer $spacer 0;
      text-align: initial;
    }
    &.float-right,
    &.pull-right {
      width: auto;
      max-width: 200px;
      margin: 0 0 $spacer $spacer;
      text-align: initial;
    }
  }
}
/* end image classes */

.lead {
  line-height: 2.4rem;
}

// Mimics the look from Bootstrap 3
blockquote {
  padding: $spacer/2 $spacer;
  margin: 0 0 $spacer;
  font-size: $blockquote-font-size;
  border-left: 0.4rem solid $light-grayish-blue;
}

.iframe {
  iframe {
    border: 0;
  }
}

.media-background-container {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.media-background, 
.media-background-container > picture {
  height: calc(100% + 1px);
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% + 1px);
  z-index: -1;
}
.object-fit-cover {
  object-fit: cover;
}

.object-position-left {
  object-position: center left;
}

.object-position-right {
  object-position: center right;
}

.object-position-bottom {
  object-position: bottom center;
}

.object-position-top {
  object-position: top center;
}

//Map
.widget {
  &.map-gallery {
    .map-link {
      position: relative;
      display: block;
      margin-top: 15px;
      .view-map {
        position: absolute;          
        bottom: 0;
        right: 0;
        background-color: #ffc423cc;
        color: $black;
        padding: 6px;
        font-weight: bold;
      }
    }      
  }
}


// Legacy styles
// Can be added back in if necessary.

/*
table {
  caption {
    color: $smallTagColor;
  }
}
*/

/*
#page-content {
  max-width: $pageMaxWidth;
  margin: 0 auto;
  background-color: $white;
}
*/

/*
.embed-responsive {
  margin-bottom: 40px;
  margin-top: 20px;
  clear: both;
}
*/

/*
@media #{breakpointSmallAndSmaller} {
  dl.event {
    dt {
      float: left;
      width: 160px;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    dd {
      margin-left: 180px;
    }
  }
}
*/
