.youvisit-launch-icon {
  &.container-fluid {
    padding: 0;
    margin: 0 0 -2px 0;
    // Prevents YouVisit from being too wide thus adding a horizontal scroll bar
    overflow: hidden; 
  }
}

.embed-youvisit {
  &.embed-youvisit-responsive {
  }
  &.embed-youvisit-sidebar {
  }
  &.pull-left,
  &.pull-right {
  }
}

@include media-breakpoint-down(sm) {
  .embed-youvisit {
    &.embed-youvisit-responsive {
    }
    &.embed-youvisit-sidebar {
    }
    &.pull-left,
    &.pull-right {
      width: 100%;
      clear: both;
    }
  }
}