// Migrated from Bootstrap 3, refactor as neccessary

/* Moved to ../_variables.scss
$offcanvas-btn-color: #fff;
$offcanvasBtnBgColor: rgba(0, 61, 76, 1);
$offcanvasBtnBgColorHover: #363534;
$offcanvasBtnBgColorHoverTranslucent: rgba(54, 53, 52, 1.0);
$offcanvas-btn-color-ql: #fff;
$offcanvas-btn-bg-color-ql: $btnBgColorPrimary;
$offcanvas-btn-bg-color-qlHover: #00a4c7;
$offcanvasBtnWidth: 200px;
$offcanvas-nav-width: 380px;
$offcanvasNavTransition: 0.5s;
$offcanvas-utility-link-hover-transition: 0.3s;
$offcanvas-utility-link-color: #cfcfcf;
$offcanvas-noindex-color: #afafaf;
$offcanvas-sitetree-toggle-size: 32px; // In pixels
$offcanvas-sitetree-border-color: rgba(255,255,255,0.2);
*/

@mixin offcanvasCollapse() {
  .offcanvas-tablist {
    right: -$offcanvas-btn-width + 72;
    .offcanvas-tab {
      .offcanvas-label {
        transition-duration: inherit;
        opacity: 0;
      }
    }
  }
}

/* Styling for tab list and tab panels in general. */
.offcanvas {
  @include offcanvasCollapse(); // Collpase tablist by default
  font-size: $fontSizeXS;
  font-family: $offcanvas-font-family;
  transition-duration: $offcanvas-nav-transition;
  .offcanvas-tablist {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: $offcanvas-btn-width;
    transition-duration: inherit;
    .offcanvas-tab {
      background-color: $offcanvas-btn-bg-color-hover;
      border: 0;
      color: $offcanvas-btn-color-ql;
      cursor: pointer;
      display: block;
      font-size: $fontSizeS;
      font-weight: bold;
      margin-bottom: 10px;
      margin-left: 18px;
      /*overflow: hidden;*/
      padding: 0.8em;
      text-align: left;
      text-decoration: none;
      /*text-overflow: ellipsis;*/
      text-transform: uppercase;
      transition-duration: inherit;
      /*white-space: nowrap;*/
      width: 100%;
      /*
      &:nth-child(n + 2) {
        display: none;
      }*/
      i {
        color: $offcanvas-btn-color;
        font-size: 26px;
        margin-right: 0.2em;
        margin-bottom: 0.12em;
        font-weight: normal;
        vertical-align: text-top;
      }
      .offcanvas-label {
        color: $offcanvas-btn-color;
        transition-duration: inherit;
        opacity: 0;
      }
      &:hover {
        background-color: $offcanvas-btn-bg-color-hover;
        margin-left: 0px;
      }
      &.ql {
        background-color: $offcanvas-btn-bg-color-ql;
        &:hover {
          background-color: $offcanvas-btn-bg-color-ql-hover;
        }
      }
    }
  }
  .offcanvas-tabpanels {
    background-color: $offcanvas-btn-bg-color-hover;
    height: 100%; /* 100% Full-height */
    overflow-x: hidden; /* Disable horizontal scroll */
    position: fixed; /* Stay in place */
    top: 0;
    transition: inherit;
    z-index: 1000; /* Stay on top */
    right: -100vw;
    width: 100vw;
    .offcanvas-tabpanel-container {
      margin: 74px 20px 0 20px;
      padding: 0;
      position: relative;
      .offcanvas-tabpanel {
        //display: block;
        &[aria-expanded="false"] {
          display: none;
          visibility: hidden;
        }
        &.open {
          //display: block;
          visibility: visible;
        }
      }
    }
    .offcanvas-close {
      button {
        font-size: 46px;
        cursor: pointer;
        border: 0;
        color: $offcanvas-btn-color;
        background-color: $offcanvas-btn-bg-color-hover;
        background-color: transparent;
      }
      position: fixed;
      // width: $offcanvas-nav-width - 40; // small and up
      text-align: right;
      transition: inherit;
      top: 0;
      // right: -$offcanvas-nav-width; // small and up
      z-index: 900;
      background-color: $offcanvas-btn-bg-color-hover;
      margin: 0 18px 0 0;

      right: -100vw;
      width: 94vw;
    }
  }
  .btn {
    color: $offcanvas-btn-color-ql;
    background-color: $offcanvas-btn-bg-color-ql;
    display: block;
    text-align: center;
    margin: 0.84em 0 0.84em 0 !important;
    font-size: $fontSizeS;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.8em;
    // width: auto; // Why?
    &:hover {
      background-color: $offcanvas-btn-bg-color-ql-hover;
    }
  }
  &.open {
    .offcanvas-tablist {
      right: -20vw !important;
      .offcanvas-tab {
        background-color: $offcanvas-btn-bg-color-hover;
        &.ql {
          background-color: $offcanvas-btn-bg-color-ql-hover;
        }
        .offcanvas-label {
          opacity: 1 !important;
        }
      }
    }
    .offcanvas-tabpanels {
      right: 0;
      .offcanvas-tabpanel {
        right: 0;
      }
      .offcanvas-close {
        right: 0;
      }
    }
  }
  &.offcanvas-collapse {
    @include offcanvasCollapse();
  }
}

/* Styling for specific types of tab panels. */
#offcanvas-site-tree,
#offcanvas-login {
  /* Styles for both site tree and utility links. */
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-weight: 500;
    font-size: $fontSizeXS;
    li {
      margin: 0;
      padding: 0;
      color: $offcanvas-btn-color;
      a,
      button {
        background-color: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding: 0;
        text-decoration: none;
      }
      .noindex {
        color: $offcanvas-noindex-color;
      }
    }
  }
  ul.site-tree {
    /* Styles for specific levels of the site tree. */
    &.level-1 {
      border-top: 2px solid $offcanvas-sitetree-border-color;
      li.root-level {
        border-bottom: 2px solid $offcanvas-sitetree-border-color;
        line-height: $offcanvas-sitetree-toggle-size;
        min-height: $offcanvas-sitetree-toggle-size;
        padding: 0.42em 0;
        > a {
          font-size: $fontSizeS; // Root level links are bigger
        }
        > .noindex {
          font-size: $fontSizeS; // Root level labels are bigger
          color: $offcanvas-noindex-color;
        }
        .site-tree-toggle {
          font-size: $fontSizeXS; // Required at this level
          float: right;
          min-width: $offcanvas-sitetree-toggle-size + 2;
          min-height: $offcanvas-sitetree-toggle-size + 2;
        }
        ul.level-2 {
          font-size: $fontSizeXS; // Everything under level 2 is smaller
          transition-duration: 0.2s;
          // Styles for showing and hiding the sub-menu.
          max-height: 0;
          visibility: hidden;
          overflow: hidden;
          // end
          &.open {
            max-height: 70em;
            visibility: visible;
            overflow: none;
          }
          li {
            padding-left: 1.2em; // Indent everything under level 2.
            a {
              color: $offcanvas-utility-link-color;
              &:hover {
                transition-duration: $offcanvas-utility-link-hover-transition;
                color: $offcanvas-btn-color;
              }
            }
          }
        }
      }
    }
    li.active > a {
      color: $offcanvas-btn-color !important;
    }
  }

  /* Styles for utility links. */
  ul.utility-links {
    font-size: $fontSizeXS;
    margin-top: 1.2em;
    li {
      padding: 0.42em 0.21em;
      &.divider {
        color: $offcanvas-utility-link-color;
        border-bottom: 1px solid $offcanvas-sitetree-border-color;
        margin: 0.42em 0;
        font-weight: bold;
      }
      a {
        color: $offcanvas-utility-link-color;
        &:hover {
          transition-duration: $offcanvas-utility-link-hover-transition;
          color: $offcanvas-btn-color;
        }
      }
    }
  }
}

// Don't forget,  login also uses styles from site tree above.
#offcanvas-login {
  ul.utility-links {
    margin-top: 0;
  }
}

#offcanvas-search {
  .form-control {
    border-radius: 0;
  }
}

/* Styles for the overlay */
.offcanvas-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 800;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background: rgba(0, 0, 0, 1);
  visibility: hidden;
  opacity: 0;
  &.on {
    opacity: 1;
    visibility: visible;
  }
}
.noscroll {
  overflow: hidden;
}

// Small and up
@include media-breakpoint-up(md) {
  .offcanvas {
    .offcanvas-tablist {
      right: 0; // small and up
      top: 20px; // small and up
      .offcanvas-tab {
        background-color: $offcanvas-btn-bg-color; // small and up
        /*
        &:nth-child(n + 2) {
          display: block;
        }
        */
        .offcanvas-label {
          opacity: 100%; // small and up
        }
      }
    }
    .offcanvas-tabpanels {
      right: -$offcanvas-nav-width; // small and up
      width: $offcanvas-nav-width; /* 0 width - change this with JavaScript */ // small and up
      /* Moved xs 
      .offcanvas-tabpanel {
        &[aria-expanded="false"] {
          display: none;
        }
        //display: none; // small and up
      }
      */
      .offcanvas-close {
        width: $offcanvas-nav-width - 38; // small and up
        right: -$offcanvas-nav-width; // small and up
      }
    }
    &.open {
      .offcanvas-tablist {
        right: $offcanvas-nav-width !important; // small and up
      }
    }
  }
  .offcanvas-overlay {
    background: rgba(54, 53, 52, 0.6); // small and up
  }
}

// Hides all tabs except for the hamburger.
@include media-breakpoint-down(sm) {
  .offcanvas-tab:not(#offcanvas-site-tree-tab) {
    visibility: hidden;
    display: none;
  }
}
