/* @mixin button-variant(
    $background, 
    $border, 
    $hover-background: darken($background, 7.5%), 
    $hover-border: darken($border, 10%), 
    $active-background: darken($background, 10%), 
    $active-border: darken($border, 12.5%)) */
.btn-primary {
  @include button-variant($btn-primary-bg-color, $btn-primary-bg-color, $btn-primary-bg-hover-color, lighten($btn-primary-bg-color, 10%), darken($dark-cyan-150, 10%), lighten($btn-primary-bg-color, 12.5%));
}

.btn-ghost {
  @include button-variant(transparent, $btn-ghost-bg-color, $btn-ghost-bg-color, $btn-ghost-bg-color, $btn-ghost-bg-color, $btn-ghost-bg-color);
}

.btn-ghost-dark {
  @include button-variant(transparent, $btn-ghost-dark-bg-color, $btn-ghost-dark-bg-color, $btn-ghost-dark-bg-color, $btn-ghost-dark-bg-color, $btn-ghost-dark-bg-color);
  color: $dark;
}

.btn-default {
  @include button-variant($btn-default-bg-color, $btn-default-bg-color, lighten($btn-default-bg-color, 7.5%), lighten($btn-default-bg-color, 10%), lighten($btn-default-bg-color, 10%), lighten($btn-default-bg-color, 12.5%));
}

.btn-link {
  // text-decoration: none !important;
}

.btn {
  .fa {
    vertical-align: text-top;
  }
}