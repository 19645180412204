#content {
  .alert {
    .alert-heading {
      font-weight: bold;
    }
    border-radius: 0;

    a {
      color: inherit;
      text-decoration: underline;
      font-weight: bold;
      &.btn-ghost-dark {
        text-decoration: none;
        font-weight: normal;
        &:hover,
        &:focus,
        &:active {
          color: $white;
          text-decoration: none;
          border-color: $white;
        }
      }
    }

    &-warning {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #483d15;
          }
        }
      }
    }

    &-primary {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #152f34;
          }
        }
      }
    }

    &-secondary {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #292a2c;
          }
        }
      }
    }

    &-success {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #3b4015;
          }
        }
      }
    }

    &-danger {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #411f23;
          }
        }
      }
    }

    &-warning {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #483d15;
          }
        }
      }
    }

    &-info {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #153239;
          }
        }
      }
    }

    &-light {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #444;
          }
        }
      }
    }

    &-dark {
      a {
        &.btn-ghost-dark {
          &:hover {
            background-color: #1f2022;
          }
        }
      }
    }

    .fa {
      font-size: 200%;
      margin-right: 10px;
      float: left;
    }
  }
}
