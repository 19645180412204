.headshot.pull-left {
  float: none;
  max-width: 100%;
}

@include media-breakpoint-up(md) {
  .headshot.pull-left {
    float: left;
    max-width: 280px;
    margin: 0 $spacer*2 0 0;
  }
}
