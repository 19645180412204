// Migrated from Bootstrap 3, refactor as neccessary

.breadcrumb > li + li:before {
  content: ">\00a0";
  color: $gray-400;
  padding: 0 5px;
}

.breadcrumb > .active {
  color: inherit;
}

#breadcrumbs {
  border-top: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  font-family: $offcanvas-font-family;
  ol {
    flex-direction: column;
    padding: 5px;
    margin: 0;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    > li {
      + li:before {
        content: ">\00a0";
      }
    }
    li {
      a {
        line-height: 38px;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  #breadcrumbs {
    margin-top: 20px - $sma-border-width;
    margin-bottom: 20px;
    ol {
      flex-direction: row;
      padding: 0;
      > li {
        + li:before {
          padding: 0 5px 0 10px;
        }
      }
      li {
        a {
          line-height: inherit;
        }
      }
    }
  }
}
