.fc-header-toolbar {
  padding: 0 14px;
  margin-top: 1em;
}
.fc-header-toolbar h2 {
  margin-top: 6px;
  font-size: 1.2rem;
}
.fc-button.fc-state-default {
  border: 0px solid;
  background-color: $navigation-bg-color;
  background-image: none;
  border-color: $navigation-bg-color;
  color: $white;
  text-shadow: none;
  box-shadow: none;
}
.fc-button.fc-state-active {
  background-color: $btn-primary-bg-color;
}
.fc-button.fc-state-hover {
  background-color: $navigation-hover-link-color;
  //-webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 0 10px 2px rgba(0,92,114, 0.2);//, 0 12px 90px 0 rgb(0 92 114 / 11%), 0 12px 90px 0 rgb(0 92 114 / 11%);
}
.fc-list-view {
  border-width: 1px 0;
}
.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  /* border-color: #ddd; */
  border: 0;
}
@media screen and (max-width: 400px) {
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-center {
    float: none;
    display: inline-block;
  }
  .fc-toolbar.fc-header-toolbar {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .fc-toolbar.fc-header-toolbar h2 {
    padding: 0 0.5rem;
  }
}
.embed-footer {
  position: fixed;
  bottom: 0;
  background-color: #eee;
  z-index: 4;
  border-top: 1px solid #ddd;
}
.embed-footer .btn-link {
  white-space: break-spaces;
}
