/* Use this file to write IE specific override styles. Media queries 
 * should be written such that they target IE. Hard-coded 
 * breakpoints... is there a better way?
 */

// Everything, including extra small
@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tiles > .tiles .tile.secondary {
    flex: 1;
    flex-basis: 28%;
  }
  .secondary-message-area .feature-list .features {
    display: block;
  }
  .secondary-message-area .feature-list .features .feature {
    flex: 1;
  }
  .secondary-message-area .combo-tiles {
    display: block;
  }
  .secondary-message-area .combo-tiles .combo-tile {
    display: block;
  }
  .secondary-message-area .image-text-overlay {
    display: flex;
  }
  .events .event-container .event-cards .event-card,
  .events .event-container .event-cards,
  .events .event-container {
    display: block;
  }
  .experiences.item-container .item {
    display: block;
  }
  .call-to-action {
    display: block;
  }
  .testimonial {
    display: block;
  }
  .modal__testimonial .modal-dialog .modal-content .modal-body {
    display: block;
  }
  .archive-list.item-container .item {
    display: block;
  }
}

// Small
@media (min-width: 576px) and (-ms-high-contrast: none), (min-width: 576px) and (-ms-high-contrast: active) {
  .events .event-container .event-cards .event-card {
    flex-basis: 100%;
  }
}

// Medium
@media (min-width: 768px) and (-ms-high-contrast: none), (min-width: 768px) and (-ms-high-contrast: active) {
  .tiles > .tiles .tile.secondary {
    flex-basis: 24%;
  }
  .events .event-container .event-cards .event-card {
    flex-basis: 41%;
  }
}

// Large
@media (min-width: 992px) and (-ms-high-contrast: none), (min-width: 992px) and (-ms-high-contrast: active) {
  .tiles > .tiles .tile.secondary {
    flex-basis: 22%;
  }
  .events .event-container .event-cards .event-card {
    flex-basis: 43%; 
  }
}

// Extra large (big boi)
@media (min-width: 1200px) and (-ms-high-contrast: none), (min-width: 1200px) and (-ms-high-contrast: active) {
  .tiles > .tiles .tile.secondary {
    flex-basis: 16%;
  }
}
