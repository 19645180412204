.navbar-page-navigation {
  border-radius: 0;
  border: 0;
  border-top: 3px solid $page-navigation-navbar-border-color;
  border-bottom: 3px solid $page-navigation-navbar-border-color;
  min-height: 0;
  margin-top: 20px;
  background-color: transparent;
  .navbar-toggle {
    border: 0;
    outline: 0;
    border-radius: 0;
  }
  .navbar-nav {
    > li {
      &.active {
        > a {
          background-color: transparent;
          font-weight: bold;
          &:hover {
            background-color: inherit;
          }
        }
      }
      > a {
        //color: #337ab7;
        text-decoration: none;
        &:hover {
          //color: #23527c;
          text-decoration: underline;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar-page-navigation {
    .navbar-collapse {
      padding-left: 0;
    }
    .navbar-nav {
      > li {
        > a {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }
}