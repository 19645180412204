/* Cookie Notification */
.cookie-notification {
  font-size: 1em;
  background-color: $cookie-notification-bg-color;
  color: $cookie-notification-color;
  position: fixed;
  padding: 1em;
  // max-width: 300px; // already commented out before migration
  z-index: 1000;
  display: none;

  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  max-width: 100%;

  &__content {
    line-height: 1.25em;
    margin: 0 2em 1em 0;
    width: 76%;
    float: none;
    width: 100%;
    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__accept {
    font-size: 1.2em;
    padding: 0.6em;
    // width: 20%; // already commented out before migration
    color: $cookie-notification-color;
    /* background-color: #da295a; */ // already commented out before migration
    border: 0;
    border-radius: 0.2em;

    width: 100%;
    float: none;
  }

  &__dismiss {
    display: none; // hidden, client didn't want it
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    color: $cookie-notification-color;
    border: 0;
    padding: 0;
    margin: 0;
    /* font-size: 2.6em; */ // already commented out before migration
    line-height: 20px;
    font-size: 46px;
    cursor: pointer;
    margin-top: -2px;
    letter-spacing: -6px;
  }
}

@include media-breakpoint-up(md) {
  .cookie-notification {
    &__content {
      float: left;
      width: 66%; 
    }

    &__accept {
      float: right;
      width: initial;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cookie-notification {
    &__content {
      width: 72%; 
    }
  }
}

@include media-breakpoint-up(xl) {
  .cookie-notification {
    &__content {
      width: 76%; 
    }
  }
}
