
/* TODO: Note that Bootstrap’s current implementation does not cover 
 * the various keyboard interactions described in the WAI-ARIA 
 * Authoring Practices 1.1 accordion pattern - you will need to 
 * include these yourself with custom JavaScript.
 */

 // Accordion card
.accordion {
  margin-bottom: $spacer;
  .card {
    border-width: $accordion-card-border-width;
    border-radius: $accordion-card-border-radius;
    border-color: $accordion-card-border-color;
    .card-header {
      background: $accordion-card-header-bg;
      padding: $accordion-card-spacer-y $accordion-card-spacer-x;
      .btn-link {
        font-size: $h4-font-size;
        color: $accordion-card-header-color;
        .fa {
          display: inline;
          position: absolute;
          right: 0.75rem;
          top: 0.75rem;
        }
      }
    }
    // I get what this does but not sure where we use it on the site.
    // Leaving it in just in case.
    dl {
      margin-bottom: 0;
      dd {
        margin-bottom: 8px;
      }
    }
  }
}

// Original Bootstrap 3 styles for reference.
/*
.panel-group {
  .panel {
    border-color: $light-grayish-blue;
    border-radius: 0;
    border-width: 0;
    box-shadow: none;
    clear: both;
    // margin-top: 12px;
    margin-top: 20px;
    .panel-heading,
    .panel-title {
      //background-color: $brandColor2;
      background: linear-gradient(45deg, #003d4c 0%, #005166 45%, #00667f 100%);
      border-radius: 0;
      border: 0;
      color: $white;
      position: relative;
      a {
        display: block;
        font-size: 140%;
        font-weight: bold;
        text-decoration: none !important;
        margin-right: 36px;
        .glyphicon {
          display: inline-block;
          position: absolute;
          right: 0;
        }
      }
    }
    .panel-body {
      border: 0;
    }
    dl {
      margin-bottom: 0;
      dd {
        margin-bottom: 8px;
      }
    }
  }
}

.panel-group {
  margin-top: 20px;
  .panel {
    border-radius: 0;
  }
}
*/
