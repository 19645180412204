.testimonial {
  display: flex;
  flex-flow: column; // xs and sm
  font-size: $testimonial-font-size;
  background-color: $testimonial-bg-color;
  margin-bottom: $spacer * 2;
  .image {
    order: 1;
    flex: 1;
    background-size: cover;
    background-position: center;
    margin-top: -0.1em; // xs and sm
    /* Aspect ratio boxes (https://css-tricks.com/aspect-ratio-boxes) */
    &::before {
      content: "";
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      // padding-top: 900px / 1600px * 100%; // 16x9
      padding-top: 540px / 540px * 100%; // 1x1
    }
    &::after {
      /* to clear float */
      content: "";
      display: table;
      clear: both;
    }
  }
  .border {
    order: 2;
    background-color: transparent;
    border: 0 !important; // xs and sm
    border-top: $testimonial-border-arrow-size/4 solid $testimonial-border-arrow-color !important; // xs and sm
    height: ($testimonial-border-arrow-size / 4) + $testimonial-border-arrow-size; // xs and sm
    &__arrow {
      position: relative;
      width: 0;
      height: 0;
      background-color: transparent;

      // xs and sm
      top: auto;
      left: 50%;
      margin-top: 0;
      margin-left: -$testimonial-border-arrow-size;
      border-top: $testimonial-border-arrow-size solid $testimonial-border-arrow-color;
      border-right: $testimonial-border-arrow-size solid transparent;
      border-bottom: $testimonial-border-arrow-size solid transparent;
      border-left: $testimonial-border-arrow-size solid transparent;
      // end xs and sm
    }
  }
  .caption {
    order: 3;
    flex: 1; // xs and sm
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    padding: $testimonial-caption-padding;
    & > * {
      margin: $testimonial-caption-margin;
    }
    .attribution {
      font-size: $testimonial-font-size-attribution;
    }
    .teaser {
      font-style: italic;
    }
    .link {
      text-transform: uppercase;
      a:not(.btn) {
        color: darken($primary, 10%);
      }
    }
  }
}
.modal__testimonial {
  font-size: $testimonial-font-size;
  .close {
    position: absolute;
    line-height: 0.6em;
    top: 10px;
    right: 10px;
    color: $white;
    //font-size: 8em; // md and up
    font-weight: 100;
    opacity: 1;
    font-size: 4em; // xs and sm
  }
  .modal-dialog {
    //top: 15%; // md and up
    //width: 70%; // md and up
    top: 7%; // xs and sm
    width: 97%; // xs and sm
    margin: 0 auto; // xs and sm
    .modal-content {
      border-radius: 0;
      border: 0;
      .modal-body {
        padding: 0;
        display: flex;
        flex-flow: column; // xs and sm
        .image {
          //min-height: 30vw; // md and up
          background-size: cover;
          background-position: center;
          flex: 1;
          min-height: 80vw; // xs and sm
          &::before {
            content: "";
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            // padding-top: 900px / 1600px * 100%; // 16x9
            padding-top: 540px / 540px * 100%; // 1x1
          } 
          &::after {
            /* to clear float */
            content: "";
            display: table;
            clear: both;
          }
        }
        .caption {
          font-size: 1.2em;
          padding: 1.6em;
          flex: 1;
          .attribution {
            font-size: 1.8em;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal__testimonial {
    .modal-dialog {
      width: 90%;
      .modal-content {
        .modal-body {
          .image {
            min-height: 30vw;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .testimonial {
    flex-flow: row;
    .image {
      margin: 0;
    }
    .border {
      height: auto;
      width: ($testimonial-border-arrow-size / 4) + $testimonial-border-arrow-size;
      border-top: 0 !important;
      border-left: $testimonial-border-arrow-size/4 solid $testimonial-border-arrow-color !important;
      &__arrow {
        // md and up
        top: 50%;
        left: 0;
        margin-top: -$testimonial-border-arrow-size;
        margin-left: -0.1em;
        border-top: $testimonial-border-arrow-size solid transparent;
        border-bottom: $testimonial-border-arrow-size solid transparent;
        border-left: $testimonial-border-arrow-size solid $testimonial-border-arrow-color;
        // end md and up
      }
    }
  }
  .modal__testimonial {
    .close {
      font-size: 8em; // md and up
    }
    .modal-dialog {
      max-width: initial;
      top: 15%; // md and up
      //width: 70%; // md and up
      .modal-content {
        .modal-body {
          flex-flow: row;
          .image {
            min-height: 30vw; // md and up
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .testimonial {
    .caption {
      flex: 2;
    }
  }
}