// Page navigation
.navigation {
  font-size: 1em;
  a {
    color: $navigation-link-color;
  }
  &.navigation-page {
    color: $navigation-color;
    margin-bottom: 22px;
    a,
    h3 {
      transition-duration: .2s;
      transition-property: background-color;
      text-decoration: none !important;
    }
    h3 {
      margin: 0;
      padding: 0;
      background-color: $navigation-bg-color;
      a,
      .root {
        display: inline-block;
        width: 100%;
        padding: 0.5em;
        margin: 0;
      }
    }
    a:hover {
      background-color: $navigation-hover-link-color;
      text-decoration: none;
      cursor: pointer;
    }
    ul {
      background-color: $navigation-bg-color;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        &.active {
          background-color: $navigation-active-link-color;
          text-decoration: none;
        }
        a,
        span {
          display: block;
          border-top: 1px solid $navigation-border-color;
          padding: .5em 0 .5em 0;
        }
      } // Indentation 
      li a,
      li span {
        padding-left: 1em;
      }
      li ul li a,
      li ul li span {
        padding-left: 2em;
      }
      li ul li ul li a,
      li ul li ul li span {
        padding-left: 3em;
      }
      li ul li ul li ul li a,
      li ul li ul li ul li span {
        padding-left: 4em;
      }
      li ul li ul li ul li ul li a,
      li ul li ul li ul li ul li span {
        padding-left: 5em;
      }
      li ul li ul li ul li ul li ul li a,
      li ul li ul li ul li ul li ul li span {
        padding-left: 6em;
      }
      li ul li ul li ul li ul li ul li ul li a,
      li ul li ul li ul li ul li ul li ul li span {
        padding-left: 7em;
      }
    }
    >ul {
      margin-left: 0.5em;
    }
  }
}


/* For legacy page navigation (on newsroom/press release pages) */
.page-nav {
  background-color: $brand-color-1 !important;
  color: $white;
  margin-top: 0 !important; 
  .panel-group {
    .panel {
      .panel-heading {
        background: transparent;
        .panel-title {
          background: transparent;
          font-size: 12px;
          a {
            font-weight: normal;
            margin-right: 0;
          }
        }
      }
    }
  }
  li>a {
    color: $white;
  }
}