.call-to-action {
  font-size: 1em;
  padding: 1.4em;
  background-color: transparent;
  border: 4px solid $brand-color-2;
  border-left-width: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  .kicker {
    font-size: 1.4em;
    line-height: normal;
    margin-bottom: 10px;
  }
  .kicker,
  .btn {
    display: block;
    width: 100%;
    text-align: center;
  }
}

@include media-breakpoint-up(md) {
  .call-to-action {
    .kicker,
    .btn {
      width: auto;
      text-align: left;
    }
    .kicker {
      flex: 1;
      margin-bottom: 0;
    }
  }
}
