//
.areas-of-study-select {
  .image-text-overlay {
    .container-center {
      flex-flow: column;
      padding-top: $spacer;
    }
  }
  .controls {
    width: 90%; // xs, sm
    margin-top: 0; // xs, sm
    margin-bottom: $spacer;
    form {
      // xs
      &.form-inline {
        margin-bottom: $spacer;
      }
      // end xs
      .input-group {
        background-color: $btn-primary-bg-color;
        padding: 0.4em;
        width: 100%;
        .form-control {
          font-size: 1em;
          border: 0;
          border-radius: 0; // height: 3em;
          height: auto; //min-width: 80%;
          //max-width: 80%;
          //width: auto;
          overflow: hidden;
        }
        .input-group-btn {
          font-size: inherit;
          &:last-child {
            & > .btn {
              margin-left: 0.4em;
            }
          }
          .btn {
            border: 0;
            border-radius: 0;
            &:hover {
              border: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .areas-of-study-select {
    .controls {
      max-width: 800px;
      width: 70%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .areas-of-study-select {
    .controls {
      max-width: initial;
      width: 60%;
    }
  }
}
