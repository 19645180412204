.newsroom:not(.calendar) {
  &.media-list {
    padding-left: 0;
    .media {
      border-bottom: 1px solid $translucent-black-10;
      margin-bottom: $spacer;
      padding-bottom: $spacer;
      .pull-left,
      img {
        display: none;
        float: none !important;
        margin-bottom: 10px;
        padding: 0;
        width: 100%;
      }
      .media-object {
        &.empty {
          display: none;
        }
        &.release {
          display: none;
        }
      }
    }
    .media-heading {
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      small {
        color: $gray-600;
        font-size: 70%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .newsroom:not(.calendar) {
    &.media-list {
      .media {
        border-bottom: 0;
      }
    }
  }
}