.azMenu {
    padding: 0;
    margin: 0 0 1em 0;
    list-style: none;

    ul.letternav {
        padding: 0;
        li {
            text-transform: uppercase;
            font-weight: bold;
            float: left;
            margin: 0 1px 1px 0;
            padding: 0;
            list-style: none;

            &:after, &:before {
                display: none;
            }

            a, &.disabled {
                padding: .25em .6em;
                color: $white;
                text-align: center;
                text-decoration: none;
                display: block;
                width: 1em;
                float: left;
                border: none;
                background: $very-dark-cyan;
                box-sizing: content-box;
            }

            a {
                text-decoration: none;
                border: none;

                &:hover, &:active, &:focus {
                    background: $dark-cyan-100;
                }
            }

            &.disabled {
                background: #eee;
                color: $very-dark-grayish-orange;

                a {
                    background: none;
                    color: $very-dark-grayish-orange;
                    padding: 0;

                    &:hover, &:focus {
                        background: none;
                        cursor: text;
                    }
                }
            }
        }
    }    
}
.faculty-item {
    strong {
        font-size: large;
    }
}