/* Includes styles for general galleries, image galleries, 
 * video galleries, and the play button used in video galleries. 
 */

/* General gallery styles */
.gallery {
  clear: both;
  margin-bottom: $spacer * 2;
  .gallery-title,
  .gallery-content {
    margin-bottom: $spacer;
  }
  .video {
    padding-bottom: 15px;
    .poster {
      display: block;
      position: relative;
    }
    .title {
      margin: 0;
      background-color: $brand-color-0;
      color: $light;
      font-size: 86%;
      padding: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: inherit;
        &:hover,
        &:active,
        &:visited {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
  .image {
    padding-bottom: 15px;
  }
  .img-responsive {
    width: 100%;
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(sm) {
  .gallery {
    margin-right: 15px;
    .video,
    .image {
      padding-right: 0;
      padding-bottom: 15px;
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

/* Play button styles (for use with video galleries) */
button.btn-play {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  // margin-left: -15px;
  // margin-top: -15px;
  margin-top: 5px;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  width: 100%;
  background-color: transparent;
  color: $gallery-play-button-color;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: $gallery-play-button-hover-color;
  }
  .fa {
    padding: 0;
    margin: 0;
    color: inherit;
    background-color: inherit;
    font-size: 88px;
  }
}

@include media-breakpoint-up(sm) {
  button.btn-play {
    .fa {
      font-size: 34px;
    }
  }
}

@include media-breakpoint-up(md) {
  button.btn-play {
    .fa {
      font-size: 34px;
    }
  }
}

@include media-breakpoint-up(lg) {
  button.btn-play {
    .fa {
      font-size: 44px;
    }
  }
}

/* Modal styles (for use with video galleries) */
.ekko-lightbox {
  .modal {
    background-color: $gallery-modal-bg-color;
  }
  .modal-header {
    border-bottom: 1px solid $gallery-modal-border-color;
    .modal-title {
      color: $gallery-modal-color;
      margin: 0;
    }
    .close {
      font-size: 32px;
      opacity: 1;
      color: $gallery-modal-color;
      text-shadow: none;
      outline: none;
      order: 2;
    }
  }
  .modal-content {
    border-radius: 0;
    border: 0;
    background-color: $gallery-modal-bg-color;
  }
  .modal-footer {
    border-top: 1px solid $gallery-modal-border-color;
    color: $gallery-modal-color;
    justify-content: flex-start;
  }
}

/* blueimp Gallery styles (for use with image galleries) */
.blueimp-gallery {
  a {
    text-decoration: none !important;
  }

  > .prev,
  > .next {
    border: 0;
    border-radius: 0;
  }

  > .prev {
    left: 0;
  }

  > .next {
    right: 0;
  }

  > .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }

  > .slides {
    > .slide {
      > .slide-content {
        max-width: 100%;
        max-height: 80%;
      }
    }
  }

  > .indicator {
    > li {
      border-radius: 0;
      height: 30px;
      width: 30px;
      background-size: contain;
    }
  }

  .description {
    display: none;
    position: absolute;
    top: 43px;
    left: 15px;
    margin: 0 40px 0 0;
    font-size: 16px;
    line-height: 30px;
    color: $white;
    text-shadow: 0 0 2px $black;
    opacity: 0.8;
  }

  &.blueimp-gallery-controls {
    .description {
      display: block;
    }
  }
}
