#tabbed-navigation {
  background-color: $brand-color-3;
  ul {
    border: 0;
    li {
     padding: 0.8rem 0;
      a {
        border: 0;
        color: $white;
        background-color: transparent;
        padding: 1rem;
        min-height: 41px;
      }
      &.active a {
        background-color: $white;
        color: $brand-color-2;
      }
      > a {
        border: 0;
        color: $white;
      }
    }
  }
}