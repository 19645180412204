// Footer
footer {
  text-align: center; // extra small, add style for small and up below
  background-color: $footer-bg-color;
  color: $footer-color;
  //font-family: $fontFamily; // commented out during migration
  padding-top: 20px;
  //border-top: $sma-border-width solid $sma-border-color;
  a,
  a:hover,
  a:visited,
  a:active {
    /* Strictly speaking, this isn't necessary. But Siteimprove 
     * doesn't recognize the use of "inherit" here, so specify
     * white with important to statisfy Siteimprove.
     */
    color: $white !important;
  }
  .logo {
    width: 100%;
    max-width: 260px;
    display: block;
    margin: 0 auto;
    margin-bottom: 14px; // extra small, add style for small and up below
  }
  .social-links {
    border-bottom: 1px solid $footer-rule-color;
    padding: 10px 0;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
    .title {
      font-size: larger;
      margin-bottom: 10px;
    }
    a:hover {
      text-decoration: none;
    }
    .fa {
      color: inherit;
      float: none;
      margin-right: 4px;
      margin-left: 4px;
    }
    ul {
      li {
        display: inline; // extra small, add style for small and up below
      }
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  .body {
    margin-bottom: 0;
  }
  .footer-copyright {
    border-top: 1px solid $footer-rule-color;
    text-align: center;
    padding: 10px 0;
    margin-top: 20px;
    a {
      text-decoration: underline;
    }
  }
  .ks-degree-stats,
  .ks-sb-2144 {
    margin-top: 20px;
    a {
      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        max-width: 200px;
      }
    }
  }

  .ks-sb-2144 {
    .btn-link {
      text-decoration: underline;
      white-space: normal;
    }
  }

  .back-to-top {
    padding: 0.5em 0.8em;
    float: right;
    right: 0px;
    bottom: 45px;
    position: fixed;
    display: none;
    background-color: $dark-cyan-150;
    color: $white;
    font-size: 1.25em;
    &:hover {
      cursor: pointer;
        background-color: $very-dark-cyan;
        color: $white;
    }
  }
}

@include media-breakpoint-up(md) {
  // Footer
  footer {
    text-align: left; // extra small, add style for small and up below
    
    /*.logo {
      margin-bottom: 14px; // extra small, add style for small and up below
    }*/
    .social-links {
      // medium and up
      margin: 0;
      padding: 0;
      max-width: initial;
      margin-bottom: 1rem;
      .fa {
        margin-right: 2rem;
      }
      ul {
        li {
          display: inline-block; // extra small, add style for small and up below
        }
      }
    }
    .title {
      margin-top: 0;
    }    
  }
}

@media (prefers-reduced-motion: reduce) {
  footer {
    .back-to-top {
      scroll-behavior: auto;
    }
  }
}
