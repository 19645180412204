.thumbnail-carousel {
  position: relative;
  margin-top: $thumbnail-carousel-title-container-offset * 2; // twice the absolute value of .title-container's "top" attribute.
  margin-bottom: $spacer;
  .carousel {
    border-top: 2px solid $thumbnail-carousel-border-color;
    border-bottom: 2px solid $thumbnail-carousel-border-color;
    padding: 8px 0;

    // xs, sm
    .carousel-inner {
      .carousel-item {
        margin: 0;
        display: block;
        img {
          width: 100%;
          margin: 10px auto;
        }
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
    // end xs, sm
  }
  // xs and sm
  &.closed {
    .carousel {
      .carousel-inner {
        max-height: 180px;
      }
    }
  }
  &.opened {
    .carousel {
      .gradient {
        display: none;
      }
    }
  }
  // end xs and sm
  .carousel-control-prev,
  .carousel-control-next {    
    width: auto;
    &:focus {
      outline: black auto 3px;
    }
  }
  
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231a1a1a' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231a1a1a' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
  .img-responsive {
    margin-bottom: 0;
  }
  .title-container {
    position: absolute;
    top: -$thumbnail-carousel-title-container-offset;
    left: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    z-index: 10;
    .title {
      color: $thumbnail-carousel-border-color;
      background-color: $white;
      text-transform: uppercase;
      padding: 0 8px;
    }
  }

  // Only for exta small and small devices
  .show-more-container {
    text-align: center;
    a {
      text-decoration: none;
      color: $thumbnail-carousel-border-color;
      &:hover {
        color: $thumbnail-carousel-border-color;
      }
    }
  }
  &.closed {
    .more {
      display: inline;
    }
    .less {
      display: none;
    }
  }
  &.opened {
    .more {
      display: none;
    }
    .less {
      display: inline;
    }
  }
  // End only for exta small and small devices
}

@include media-breakpoint-up(md) {
  .thumbnail-carousel {
    .carousel {
      .carousel-inner {
        .carousel-item {
          display: none;
          &.active {
            display: block;
          }
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        display: flex;
      }
    }

    &.closed {
      .carousel {
        .carousel-inner {
          max-height: auto;
        }
      }
    }
    .show-more-container {
      display: none;
    }
  }
}
